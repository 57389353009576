.flex {
    display: flex;
    vertical-align: middle;
}

.flexRight {
    justify-content: end;
}

.flexCentered {
    align-items: center;
    justify-content: center;
}

.flexCenteredVert {
    align-items: center;
}

.flexGrow {
    flex-grow: 1;
}



.fontBlack {
    font-weight: 900;
}

.fontNormal {
    font-weight: 400;
}