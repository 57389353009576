.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu !important;
} */


@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: local('Lato'), url(./Lato-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: local('Lato'), url(./Lato-Regular.ttf) format('truetype');
}


@font-face {
  font-family: 'Lato';
  font-weight: 600;
  src: local('Lato'), url(./Lato-Bold.ttf) format('truetype');
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: Lato, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji I !important;
  font-feature-settings: normal;
}


p {
  color: #435366;
  font-weight: 500;
  font-size: 1.5rem !important;
  line-height: 2 !important;
  letter-spacing: .02857em !important;
}